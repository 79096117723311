import React from "react"
import Img from "gatsby-image"
import Grid from "@material-ui/core/Grid"
import Section from "components/section"
import Svg from "components/svg"

import patchIcon from "images/patch.svg"

import style from "./index.module.scss"

const SectionAboutMe = ({image, children}) => (
  <Section
    hasMargin={true}
  >
    <Grid container spacing={4}>
      <Grid item xs={12} sm={6} md={5} className={style.imageWrapper}>
        <Img
          className={style.aboutMeImage}
          fluid={image}
        />
        <Svg
          className={style.patch}
          src={patchIcon}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={7}>
        {children}
      </Grid>
    </Grid>
  </Section>
)

export default SectionAboutMe
