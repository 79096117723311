import React from "react"
// import PropTypes from "prop-types"
import { RichText } from "prismic-reactjs"
import style from "./index.module.scss"

const Timeline = ({ heading, fields }) => {

  const rendered_items = fields.map( (item, index) => (
      <li key={index} className={style.item}>
        <span className={style.text}>
          {item.timeline_heading.text}
        </span>
      </li>
    )
  )

  return (
    <>
      <RichText
        render={heading.raw}
      />
      <ol className={style.timeline}>
        {rendered_items}
      </ol>
    </>

  )
}

// Timeline.propTypes = {
//   items: propTypes.arrayOf
// }


export default Timeline
