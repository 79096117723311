import React from "react"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"
import Layout from "components/layout"
import Section from "components/section"
import Pattern from "components/pattern"
import Grid from "@material-ui/core/Grid"
import Atf from "components/atf"
import Timeline from "components/timeline"
import SectionAboutMe from "sections/about-me"
import SEO from "components/seo"


import style from "./about.module.scss"

const AboutPage = ({data}) => {

  const node = data.allPrismicUberMich.edges[0].node.data;
  const slice_edges  = data.allPrismicUberMichBodyTimeline.edges;

  const timelines = slice_edges.map( ({node}, index) => (
      <Timeline
        keay={index}
        heading={node.primary.timeline_heading}
        fields={node.items}
      />
    )
  )

  return (
    <Layout>
      <SEO
        title="Kontakt"
        description="Möchten Sie einen Termin für ein Erstgespräch vereinbaren oder haben Fragen? Senden Sie mir eine Nachricht über das Kontaktformular oder rufen Sie mich an."
        canonical
        canonicalUri="/ueber-mich/"
      />
      <Atf heading="Über mich" />
      <SectionAboutMe
        image={node.intro_portrait.localFile.childImageSharp.fluid}
      >
        <div className={style.introHeading}>
          <h2>
            {node.intro_heading.text}
          </h2>
        </div>
        <div className={style.introTitle}>
          <RichText
            render={node.intro_sub_heading.raw}
          />
        </div>
        <RichText
          render={node.intro_text.raw}
        />
      </SectionAboutMe>

      <Pattern
        color="grey"
        size="md"
        offset="sm"
        position="right"
      />

      <Section
        hasMargin={true}
        hasBackground={true}
        hasPadding={true}
      >
        <Grid container spacing={0}>
          <Grid item xs={12}>
            {timelines}
          </Grid>
          <Grid item xs={12}>
            <h2>
              {node.education_heading.text}
            </h2>
            <RichText
              render={node.education_text.raw}
            />
          </Grid>
        </Grid>
      </Section>

      <Pattern
        color="yellow"
        size="md"
        offset="xl"
        position="left"
      />

      <Section>
        <Grid
          container
          spacing={0}
          justify="center"
        >
          <Grid item xs={12} md={8} className="text-center">
            <h2>
              {node.status_heading.text}
            </h2>
          </Grid>
          <Grid item xs={12} md={8}>
            <RichText
              render={node.status_text.raw}
            />
          </Grid>
        </Grid>
      </Section>

      <Pattern
        color="grey"
        size="sm"
        offset="sm"
        position="right"
      />

      <Section
        hasMargin={true}
        hasBackground={true}
        hasPadding={true}
      >
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <h2>
              {node.membership_heading.text}
            </h2>
            <RichText
              render={node.membership_text.raw}
            />
          </Grid>
        </Grid>
      </Section>

    </Layout>
  )
}

export const query = graphql`
query AboutMePageQuery {

  allPrismicUberMich {
    edges {
      node {
        data {
          intro_heading {
            raw
            text
          }
          intro_sub_heading {
            raw
            text
          }
          intro_portrait {
            localFile {
              childImageSharp {
                fluid(maxWidth: 500){
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          intro_text {
            raw
            text
          }

          status_heading {
            raw
            text
          }
          status_text {
            raw
          }

          education_heading {
            raw
            text
          }
          education_text {
            raw
          }

          membership_heading {
            text
          }
          membership_text {
            raw
          }
        }
      }
    }
  }

  allPrismicUberMichBodyTimeline {
    edges {
      node {
        primary {
          timeline_heading {
            raw
          }
        }
        items {
          timeline_end {
            text
          }
          timeline_heading {
            text
          }
          timeline_start {
            text
          }
        }
      }
    }
  }

}
`

export default AboutPage
